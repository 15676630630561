import * as Url from 'node:url';
import {LoggerImpl} from '../../shared/src/Logger.js';
import {BrowserConsoleLogger} from './browser-console-logger.js';
import {HtmlConsoleLogger} from './html-console-logger.js';
import {BUILD_TIME, VERSION_NUMBER} from './version.js';

let finishedThisModule = false;

// this is all a bit tricky, I had it a few times that the serviceworker kept serving broken code which prohibited
// started including installing a new service worker

setTimeout(() => {
    if (!finishedThisModule) {
        console.error("Module load timeout reached");
        if ('serviceWorker' in navigator) {
            console.log('Unregistering service worker');
            navigator.serviceWorker.getRegistrations().then(registrations => {
                registrations.forEach(registration => {
                    registration.unregister().then(unregistered => {
                        console.log(`Unregistered service worker: ${unregistered}`);
                    });
                });
            });
        }
    }
}, 30000);

const onScreenLogEnabled = false;

let onScreenLog: HtmlConsoleLogger | null = null;

if (onScreenLogEnabled) {
    const onScreenLogEl = document.createElement('div');
    onScreenLogEl.id = 'on-screen-log';
    document.body.appendChild(onScreenLogEl);
    onScreenLog = new HtmlConsoleLogger('on-screen-log', 200);
}

export const globalLogger = onScreenLog ? new LoggerImpl([new BrowserConsoleLogger(), onScreenLog]) : new LoggerImpl([new BrowserConsoleLogger()]);

globalLogger.info('Logger initialized');
globalLogger.info(`Build time: ${BUILD_TIME} Version: ${VERSION_NUMBER}`);

export const globalVersion = {
    buildTime: BUILD_TIME,
    versionNumber: VERSION_NUMBER,
};

// This is done via code because in index.html parcel omits a / prefix and the browser tries to load the
// font relative to the current path
( async() => {
    const font1Url = new URL('/assets/fonts/Capture_it.ttf', import.meta.url);
    const binary = await fetch(font1Url);
    const resp = await binary.arrayBuffer();
    const font = new FontFace('Capture_it', resp, {
        style: 'normal',
        weight: 'normal'
    });
    document.fonts.add(font);
})();


// font.load().then(function (loadedFont) {
//     document.fonts.add(loadedFont);
//     console.log('Font loaded successfully');
// }).catch(function (error) {
//     console.error('Failed to load font:', error);
// });

    const registerServiceWorker = async () => {
        if ('serviceWorker' in navigator) {
            try {
                const registration = await navigator.serviceWorker.register(
                    new URL('service-worker.js', import.meta.url),
                    {type: 'module'},
                );

                if (registration.installing) {
                    globalLogger.info('Service worker installing');
                } else if (registration.waiting) {
                    globalLogger.info('Service worker installed');
                } else if (registration.active) {
                    globalLogger.info('Service worker is active');
                }
            } catch (error) {
                globalLogger.error(`Registration failed with ${error}`);
            }
        }
    };

    if(window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1')
{
    registerServiceWorker().then(() => {
        globalLogger.info('Service worker handling finished');
    });
}
else
{
    globalLogger.info('Service worker handling skipped');
}


import('./frontend.js').then(() => {
    globalLogger.info('Frontend loaded');
    finishedThisModule = true;
});
