// disable eslint for this file
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum LogLevel {
    DEBUG = 'debug',
    INFO = 'info',
    WARN = 'warn',
    ERROR = 'error'
}

export interface LogEntry {
    level: LogLevel;
    message: string;
    timestamp: Date;
    params?: any[];
}

export interface LoggerSink {
    log: (entry: LogEntry) => void;
}

export interface Logger {
    log(level: LogLevel, message: string, ...params: any[]): void;

    debug(message: string, ...params: any[]): void;

    info(message: string, ...params: any[]): void;

    warn(message: string, ...params: any[]): void;

    error(message: string | Error, ...params: any[]): void;
}

// eslint-disable-next-line @shopify/no-fully-static-classes
export class LoggerUtil {
    public static to24HourTime(date: Date): string {
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    }

    // https://stackoverflow.com/questions/9382167/serializing-object-that-contains-cyclic-object-value
    private static decycle(obj: any, stack: any[] = []): any {
        if (!obj || typeof obj !== 'object') {
            return obj;
        }

        if (stack.includes(obj)) {
            return null;
        }

        const s = stack.concat([obj]);

        return Array.isArray(obj)
            ? obj.map((x) => LoggerUtil.decycle(x, s))
            : Object.fromEntries(
                Object.entries(obj)
                    .map(([k, v]) => [k, LoggerUtil.decycle(v, s)]),
            );
    }

    public static formatSmall(entry: LogEntry): string {
        const fst = `${LoggerUtil.to24HourTime(entry.timestamp)} [${entry.level[0]}] ${entry.message}`;
        if (entry.params && entry.params.length > 0) {
            return `${fst} ${entry.params.map((param) => JSON.stringify(LoggerUtil.decycle(param))).join(' ')}`;
        } else {
            return fst;
        }
    }
}

export class LoggerImpl implements Logger {
    private sinks: LoggerSink[] = [];

    constructor(sinks: LoggerSink[] = []) {
        this.sinks = sinks;
    }

    registerSink(sink: LoggerSink): void {
        this.sinks.push(sink);
    }

    log(level: LogLevel, message: string, ...params: any[]): void {
        const timestamp = new Date();
        const entry: LogEntry = {level, message, timestamp, params};
        this.sinks.forEach((sink) => sink.log(entry));
    }

    debug(message: string, ...params: any[]): void {
        this.log(LogLevel.DEBUG, message, ...params);
    }

    info(message: string, ...params: any[]): void {
        this.log(LogLevel.INFO, message, ...params);
    }

    warn(message: string, ...params: any[]): void {
        this.log(LogLevel.WARN, message, ...params);
    }

    error(message: string | Error, ...params: any[]): void {
        const msg = message instanceof Error ? (message.stack ?? 'stack not available') : message;
        this.log(LogLevel.ERROR, msg, ...params);
        if (params) {
            // eslint-disable-next-line no-console
            params.forEach((param) => console.error(param));
        }
    }
}
