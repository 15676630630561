import {LogEntry, LoggerSink, LoggerUtil} from '../../shared/src/Logger.js';

export class HtmlConsoleLogger implements LoggerSink {
    private consoleElement: HTMLDivElement;
    private lineContainer: HTMLDivElement;

    constructor(public id: string, public maxHeight: number) {
        this.consoleElement = document.getElementById(id) as HTMLDivElement;

        if (!this.consoleElement) {
            throw new Error(`Element with id "${id}" not found`);
        }

        this.consoleElement.style.height = `${maxHeight}px`;
        this.consoleElement.style.maxHeight = `${maxHeight}px`;
        this.consoleElement.style.width = '100%';
        this.consoleElement.style.overflowY = 'auto';
        this.consoleElement.style.border = '1px solid black';
        this.consoleElement.style.padding = '0px';
        this.consoleElement.style.margin = '0px';
        this.consoleElement.style.top = '0px';
        this.consoleElement.style.fontFamily = 'monospace';
        this.consoleElement.style.whiteSpace = 'pre-wrap';
        this.consoleElement.style.backgroundColor = '#f0f0f040';
        this.consoleElement.style.position='absolute';
        this.consoleElement.setAttribute('class', 'fs-xs');

        this.lineContainer = document.createElement('div');
        this.consoleElement.appendChild(this.lineContainer);
    }

    addLine(line: string) {
        const lineElement = document.createElement('div');
        lineElement.style.lineBreak = 'anywhere';
        lineElement.textContent = line;
        this.lineContainer.appendChild(lineElement);

        // Scroll to the bottom
        this.consoleElement.scrollTop = this.consoleElement.scrollHeight;
    }

    log(entry: LogEntry): void {
        this.addLine(LoggerUtil.formatSmall(entry));
        // if (entry.error) {
        //     const err = entry.error as unknown as Error;
        //     this.addLine(err.message);
        // }
    }
}
